import React, { useEffect, useState } from "react";
import axios from "axios";

const Manufacturer = ({ selectedLetter }) => {
  const [manufacturers, setManufacturers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null); // יצירת משתנה לשגיאות

  useEffect(() => {
    const fetchManufacturers = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/companies/manufacturer`,
          {
            params: {
              letter: selectedLetter || "",
            },
          }
        );
        setManufacturers(response.data);
      } catch (error) {
        console.error("Failed to fetch manufacturers:", error);
        setError("Failed to fetch manufacturers");
      } finally {
        setIsLoading(false);
      }
    };

    fetchManufacturers();
  }, [selectedLetter]); // רכיב זה ירוץ מחדש בכל פעם שselectedLetter משתנה

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>; // הצגת השגיאה אם קיימת
  return (
    <div>
      <h2>Manufacturers</h2>
      <ul className="vendor-list">
        {manufacturers.map((manufacturer) => (
          <li key={manufacturer.id} className="vendor-item">
            {manufacturer.url ? (
              <a
                href={manufacturer.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {manufacturer.name_en}
              </a>
            ) : (
              <span>{manufacturer.name_en}</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Manufacturer;
