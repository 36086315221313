// AlphabeticFilter.js
import React from 'react';

const AlphabeticFilter = ({ onSelectLetter }) => {
  const letters = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i)); // Generates A-Z

  return (
    <div className='alphabetic-filter'>
      {letters.map(letter => (
        <button key={letter} onClick={() => onSelectLetter(letter)}>{letter}</button>
      ))}
    </div>
  );
};

export default AlphabeticFilter;
