import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Supplier = ({ selectedLetter }) => {
  const [suppliers, setSuppliers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null); // יצירת משתנה לשגיאות

  useEffect(() => {
    const fetchSuppliers = async () => {
      setIsLoading(true); // התחלת טעינה
      setError(null); // איפוס השגיאה לפני כל ניסיון טעינה חדש
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/companies/supplier`, {
          params: {
            letter: selectedLetter || ''
          }
        });
        setSuppliers(response.data);
      } catch (error) {
        console.error('Failed to fetch suppliers:', error);
        setError('Failed to fetch suppliers'); // שמירת השגיאה
      } finally {
        setIsLoading(false); // סיום תהליך הטעינה
      }
    };

    fetchSuppliers();
  }, [selectedLetter]); // רכיב זה ירוץ מחדש בכל פעם שselectedLetter משתנה

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>; // הצגת השגיאה אם קיימת

  return (
    <div>
      <h2>Suppliers</h2>
      <ul className="vendor-list">
        {suppliers.map(supplier => (
          <li  key={supplier.id} className="vendor-item">
          {suppliers.url ? (
            <a
              href={supplier.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {supplier.name_en}
            </a>
          ) : (
            <span>{supplier.name_en}</span>
          )}</li>
        ))}
      </ul>
    </div>
  );
};

export default Supplier;
