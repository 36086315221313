import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Manufacturer from "../components/Manufacturer";
import Supplier from "../components/Supplier";
import EmptyComponent from "../components/EmptyComponent";
import AlphabeticFilter from "../components/AlphabeticFilter";
import '../components/css/Vendors.css'
function Vendors() {
  const [activeTab, setActiveTab] = useState("manufacturer");
  const [selectedLetter, setSelectedLetter] = useState(""); // מוסיפים מדיניות לאות הנבחרת

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLetterSelect = (letter) => {
    setSelectedLetter(letter); // עדכון האות הנבחרת
  };

  return (
    <div>
      <Helmet>
        <title>Home Page - My eDMAC</title>
        <meta
          name="description"
          content="Welcome to our homepage where you can find various products and services."
        />
      </Helmet>
      <div className="Vendors">
        <div className="tabs">
          <button
            className={activeTab === "manufacturer" ? "active" : ""}
            onClick={() => handleTabClick("manufacturer")}
          >
            Manufacturer
          </button>
          <button
            className={activeTab === "suppliers" ? "active" : ""}
            onClick={() => handleTabClick("suppliers")}
          >
            Suppliers
          </button>
          <button
            className={activeTab === "empty" ? "active" : ""}
            onClick={() => handleTabClick("empty")}
          >
            Empty
          </button>
        </div>
        <AlphabeticFilter onSelectLetter={handleLetterSelect} />
        <div className="tab-content">
          {activeTab === "manufacturer" && (
            <Manufacturer selectedLetter={selectedLetter} />
          )}
          {activeTab === "suppliers" && (
            <Supplier selectedLetter={selectedLetter} />
          )}
          {activeTab === "empty" && <EmptyComponent />}
        </div>
      </div>
    </div>
  );
}

export default Vendors;
