import React, { useState, useEffect } from "react";
import axios from "axios";
import VideoComponent from '../components/VideoComponent';
import videoFile from '../assets/Video/H.mp4';
import "../components/css/GlossaryLayout.css";

const TermsGlossary = () => {
  const [categories, setCategories] = useState([]); // אחסון הקטגוריות
  const [terms, setTerms] = useState([]); // אחסון הערכים בגלוסרי
  const [selectedCategory, setSelectedCategory] = useState(null); // קטגוריה נבחרת
  const [selectedSubCategory, setSelectedSubCategory] = useState(null); // תת-קטגוריה נבחרת
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openCategories, setOpenCategories] = useState({}); // ניהול פתיחת הקטגוריות והתת-קטגוריות
  const [openTerms, setOpenTerms] = useState({}); // ניהול פתיחה וסגירה של ערכים בגלוסרי
  
  // שליפת קטגוריות ותת-קטגוריות מהשרת
  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/categories`
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setError("Failed to fetch categories.");
    }
  };

  // שליפת נתונים מהשרת לפי קטגוריה או תת-קטגוריה
  const fetchTerms = async (categoryId = null, subCategoryId = null) => {
    setLoading(true);
    setError(null); // איפוס השגיאה לפני תחילת הבקשה

    try {
      let url = "";
      if (subCategoryId) {
        url = `${process.env.REACT_APP_API_URL}/terms/subcategory/${subCategoryId}`;
      } else if (categoryId) {
        url = `${process.env.REACT_APP_API_URL}/terms/${categoryId}`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/terms/all`; // שליפת כל הערכים אם אין קטגוריה נבחרת
      }

      console.log("Fetching from URL:", url); // הצגת ה-URL כדי לוודא שהוא תקין

      const response = await axios.get(url);

      // בדוק אם התגובה תקינה או קיימת
      if (response && response.status === 200 && response.data) {
        console.log("Response received:", response); // הצגת התגובה המלאה בקונסול
        setTerms(response.data); // הגדרת המונחים מהתשובה
        setError(null); // מחיקת השגיאה אם הכל בסדר
      } else {
        console.error("Unexpected response:", response); // הצגת תגובה לא צפויה בקונסול
        setError("Failed to fetch terms. Unexpected response from server."); // הצגת שגיאה למשתמש
      }
    } catch (error) {
      console.error("Error fetching terms:", error); // הצגת השגיאה המלאה בקונסול
      setError("Failed to fetch terms. Please try again later."); // הצגת הודעת שגיאה למשתמש
    }

    setLoading(false); // סיום טעינה
  };

  // פתיחה וסגירה של קטגוריה להצגת תת-קטגוריות
  const toggleCategory = (categoryId) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  // פתיחה וסגירה של ערכי גלוסרי
  const toggleTerm = (termId) => {
    setOpenTerms((prevState) => ({
      ...prevState,
      [termId]: !prevState[termId], // פתיחה וסגירה של ערך גלוסרי ספציפי
    }));
  };

  // שליפת הקטגוריות והערכים בטעינת הקומפוננטה
  useEffect(() => {
    fetchCategories(); // שליפת הקטגוריות
    fetchTerms(); // שליפת כל הערכים כברירת מחדל
  }, []);

  const fetchCategoryData = async (categoryId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories/${categoryId}`);
      if (response.status === 200 && response.data) {
        return response.data;
      } else {
        console.error('Failed to fetch category data:', response);
        return null;
      }
    } catch (error) {
      console.error('Error fetching category data:', error);
      return null;
    }
  }
  // טיפול בלחיצה על קטגוריה
  const handleCategoryClick = async (categoryId) => {
    // טעינת נתוני הקטגוריה מה-API
    const categoryData = await fetchCategoryData(categoryId);
    setSelectedCategory(categoryData);
    // טעינת המונחים לפי קטגוריה
    fetchTerms(categoryId);
  };

  // טיפול בלחיצה על תת-קטגוריה
  const handleSubCategoryClick = (subCategoryId) => {
    setSelectedSubCategory(subCategoryId); // שמירת תת-קטגוריה נבחרת
    fetchTerms(null, subCategoryId); // שליפת הערכים לפי תת-קטגוריה
  };

  return (
    <div className="TermsGlossary">
      <VideoComponent fileName={videoFile} cropHeight="400px" position="top" />
      <div className="container">
        {/* תפריט קטגוריות */}
        <div className="categories-menu">
          <button onClick={() => fetchTerms(null)} className="category-button">
            All Terms
          </button>
          
          {categories.map((category) => (
            <div key={category.id}>
              <button
                onClick={() => {
                  toggleCategory(category.id); // פתיחת הקטגוריה להצגת תת-קטגוריות
                  handleCategoryClick(category.id); // לחיצה על קטגוריה להצגת הערכים
                }}
                className={`category-button ${
                  selectedCategory === category.id ? "selected" : ""
                }`}
              >
                {category.name}{" "}
                {category.subcategories.length > 0
                  ? openCategories[category.id]
                    ? "▼"
                    : "▶"
                  : ""}
              </button>
              {/* הצגת תת-קטגוריות */}
              {openCategories[category.id] &&
                category.subcategories.length > 0 && (
                  <div className="subcategories-menu">
                    {category.subcategories.map((subcategory) => (
                      <button
                        key={subcategory.id}
                        onClick={() => handleSubCategoryClick(subcategory.id)} // לחיצה על תת-קטגוריה להצגת הערכים
                        className={`subcategory-button ${
                          selectedSubCategory === subcategory.id
                            ? "selected"
                            : ""
                        }`}
                      >
                        {subcategory.name}
                      </button>
                    ))}
                  </div>
                )}
            </div>
          ))}
        </div>

        {/* הצגת הערכים בגלוסרי */}
        <div className="content">
          <h1 className="content-title">Terms Glossary</h1>
          {selectedCategory && (
          <>
            <h2>{selectedCategory.name}</h2>
            <p className="introductory-text">{selectedCategory.introductory_text}</p>
          </>
        )}
          {loading && <p>Loading...</p>} {/* הצגת הודעת טעינה */}
          {/* הצגת שגיאה רק אם קיימת שגיאה אמיתית */}
          {error && <p style={{ color: "red" }}>{error}</p>}
          {/* בדיקה אם אין ערכים בגלוסרי */}
          {terms.length === 0 && !loading && !error && (
            <p>No terms available in this category.</p> // הצגת הודעה אם אין ערכים
          )}
          {/* הצגת המונחים אם הם קיימים */}
          {terms.length > 0 &&
            terms.map((term) => (
              <div
                key={term.id}
                className={`term-entry ${openTerms[term.id] ? "open" : ""}`}
              >
                <h2
                  className="term-title"
                  onClick={() => toggleTerm(term.id)} // לחיצה על שם הגלוסרי לפתיחת התוכן
                  style={{ cursor: "pointer" }}
                >
                  {term.term}
                </h2>
                {/* הצגת התוכן רק אם הערך פתוח */}
                <div className="term-content">
                  {openTerms[term.id] && (
                    <>
                      <p>{term.definition}</p>
                      <p>
                        <strong>Example:</strong> {term.example} <br />
                        {term.external_link && (
                          <p>
                            <a
                              href={term.external_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Visit Site
                            </a>
                          </p>
                        )}
                      </p>
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TermsGlossary;
